<template>
    <div>
        <div class="input_block">
            <input type="text" v-model="direccion.title" placeholder="Nombre de dirección"/>
        </div>
        <div class="input_block">
            <input type="text" v-model="direccion.name" placeholder="Nombre" maxlength="50"/>
        </div>
        <div class="input_block">
            <input type="text" v-model="direccion.lastname" placeholder="Apellidos"/>
        </div>
        <div class="input_block">
            <input type="tel" v-model="direccion.phone" placeholder="Teléfono"/>
        </div>
        <div class="input_block">
            <input type="text" v-model="direccion.address" placeholder="Dirección"/>
        </div>
        <div class="input_block">
            <input type="text" v-model="direccion.address2" placeholder="Dirección (2ª linea opcional)"/>
        </div>
        <div class="input_block">
            <input type="text" v-model="direccion.dni" placeholder="Dni"/>
        </div>
        <div class="input_block">
            <input type="text" v-model="direccion.cp" placeholder="Código postal"/>
        </div>
        <div class="input_block">
            <select v-model="direccion.country">
                <option :value="0" selected disabled>Pais</option>
                <option :value="pais" v-for="(pais, index) in paises" :key="index">{{pais.name}}</option>
            </select>
        </div>
        <div class="input_block">
            <select :disabled="direccion.country ? false : true" v-model="direccion.province">
                <option :value="0" selected disabled>Provincia</option>
                <option :value="provincia.CPRO" v-for="(provincia, index) in provincias" :key="index">{{provincia.PRO}}</option>
            </select>
        </div>
        <div class="input_block">
            <select :disabled="direccion.province ? false : true" v-model="direccion.city">
                <option :value="0" selected disabled>Localidad</option>
                <option :value="localidad.DMUN50" v-for="(localidad, index) in localidades" :key="index">{{localidad.DMUN50}}</option>
            </select>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Direccion',
    props:{
        direccion: Object,
        countries: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    data: function(){
        return{
            provincias: [],
            localidades: [],
            paises: []
        }
    },
    methods: {
        getProvinces: function(id){
            axios
                .get(process.env.VUE_APP_GEOLOCATION+'provincias')
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.provincias = rs.data.data;
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        },
        getCities: function(id){
            axios
                .get(process.env.VUE_APP_GEOLOCATION+'municipios', {
                    params: {
                        CPRO: id
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.localidades = rs.data.data;
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        },
        getCountries: function(id){
            axios
                .get(process.env.VUE_APP_URL+'countries')
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.paises = rs.data;
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        },
    },
    created: function(){
        if(this.countries.length <= 0)
            this.getCountries();
        else
            this.paises = this.countries;

        if(this.direccion.country)
            this.getProvinces();
        if(this.direccion.province)
            this.getCities(this.direccion.province);
    },
    watch: {
        'direccion.country': function(){
            this.getProvinces(this.direccion.country);
        },
        'direccion.province': function(){
            if(this.direccion.province)
                this.getCities(this.direccion.province);
        },
        countries: function(){
            this.paises = this.countries;
        }
    }
}
</script>