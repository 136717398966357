<template>
    <div>
        <section class="login">
            <div class="half_inner height_half_inner">
                <header class="section_header">
                    <h2 class="text_xxl text_white text_300">Checkout</h2>
                </header>
                <div class="content_block padded rounded white" v-if="user">
                    <div class="grid grid_pad flex_space checkout_screen">
                        <div class="grid_col grid_col_phi_2_3">
                            <div class="grid_col_box">
                                <form class="form" @submit.prevent="envio">

                                    <div class="grid_col_box">

                                        <h2 class="text_m mb30"><span class="ball_num local_color">01</span>Direccion de envío</h2>
                                        <div class="input_block">
                                            <select v-model="direction">
                                                <option :value="-1">CREAR NUEVA DIRECCION</option>
                                                <option :value="index" v-for="(direcion, index) in user.addresses" :key="index">{{direcion.title}}</option>
                                            </select>
                                        </div>
                                        <Direccion :direccion="direccion" :countries="paises"/>
                                        <div class="input_block">
                                            <div class="legal_line">
                                                <p class="legal mt10">Los campos son imprescindibles</p>
                                            </div>
                                        </div>
                                        <div class="form_block collapsible" v-if="direccion && direccion.country && this.hasEnvio()" :style="{'max-height': direccion.country ? '9999px' : '0px'}">
                                            <h2 class="text_m mb30 mt20"><span class="ball_num local_color">02</span>Metodo de envío</h2>
                                            <div class="input_block" style="margin-bottom: 40px;">
                                                <div class="checkout_method">
                                                    <div class="checkout_method_option" :class="{selected:envio==1}">
                                                        <label>
                                                            <input type="radio" name="envio" :value="1" v-model="envio">
                                                            <span><b>Metodo ordinario: de 2-7 dias laborables</b></span>
                                                            <span><b>{{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(paises.find(el => el.id == direccion.country.id).ordinary)}}</b></span>
                                                        </label>
                                                    </div>
                                                    <div class="checkout_method_option" :class="{selected:envio==2}">
                                                        <label>
                                                            <input type="radio" name="envio" :value="2" v-model="envio">
                                                            <span><b>Metodo express: de 1-2 dias laborables</b></span>
                                                            <span><b>{{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(paises.find(el => el.id == direccion.country.id).express)}}</b></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_block collapsible" v-if="envio || envio === -1" :style="{'max-height': envio ? '9999px' : '0px'}">
                                            <h2 class="text_m mb30 mt30"><span class="ball_num local_color">03</span>Metodo de pago</h2>
                                            <div class="input_block">
                                                <div class="checkout_method">
                                                    <div class="checkout_method_option"  :class="{selected:pago==1}">
                                                        <label>
                                                            <input type="radio" name="pago" :value="1" v-model="pago">
                                                            <span><b>Tarjeta de debito/credito</b></span>
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--
                                        <div class="form_block collapsible" v-if="pago == 1" :style="{'max-height': pago == 1 ? '9999px' : '0px'}">
                                            <div class="input_block mt20">
                                                <input type="tel" placeholder="Número de tarjeta" maxlength="16" v-model="tarjeta">
                                            </div>
                                            <div class="input_block">
                                                <input type="text" placeholder="Titular de la tarjeta" maxlength="50" v-model="titular">
                                            </div>
                                            <div class="input_block">
                                                <div class="grid grid_pad flex_space card_form">
                                                    <div class="grid_col">
                                                        <div class="grid_col_box">
                                                            <select v-model="mes">
                                                                <option :value="0" selected disabled>Mes</option>
                                                                <option :value="mes" v-for="(mes, index) in months" :key="index">{{mes}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="grid_col">
                                                        <div class="grid_col_box">
                                                            <select v-model="year">
                                                                <option :value="0" selected disabled>Año</option>
                                                                <option :value="year" v-for="(year, index) in years" :key="index">{{year}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="grid_col">
                                                        <div class="grid_col_box">
                                                            <span>
                                                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                    viewBox="0 0 86.2 51.1" style="enable-background:new 0 0 86.2 51.1;" xml:space="preserve">
                                                                <g>
                                                                    <path d="M25.8,32.2H5.7c-0.6,0-1.1-0.5-1.1-1.1V16.1h26.1c2.2-2.9,5-5.2,8.3-6.9H4.6V5.7c0-0.6,0.5-1.1,1.1-1.1h43.7
                                                                        c0.6,0,1.1,0.5,1.1,1.1v0.9c1.6,0,3.1,0.2,4.6,0.6V4.6c0-2.5-2.1-4.6-4.6-4.6h-46C2.1,0,0,2.1,0,4.6v27.6c0,2.5,2.1,4.6,4.6,4.6h22
                                                                        C26.2,35.3,25.9,33.8,25.8,32.2z"/>
                                                                    <path d="M9.2,20.7V23H27c0.3-0.8,0.6-1.6,0.9-2.3H9.2z"/>
                                                                    <rect x="9.2" y="25.3" width="13.8" height="2.3"/>
                                                                    <path class="st0" d="M77.2,21.6l-2.4,2.3l5.1,5.1h-9.7c-0.8-10.5-9.6-18.8-20.3-18.8c-11.3,0-20.4,9.2-20.4,20.4
                                                                        s9.2,20.4,20.4,20.4c10.7,0,19.5-8.3,20.3-18.8h9.7l-5.1,5.1l2.4,2.3l9.1-9.1L77.2,21.6z M49.9,47.1c-9.1,0-16.4-7.4-16.4-16.4
                                                                        c0-9.1,7.4-16.4,16.4-16.4c9.1,0,16.4,7.4,16.4,16.4C66.3,39.7,58.9,47.1,49.9,47.1z"/>
                                                                    <g>
                                                                        <path d="M45.9,29.4c0,2.7-1.4,5.3-4.2,5.3c-1.9,0-2.9-1.3-2.9-3.1c0-2.4,1.3-5.4,4-5.4C45.1,26.2,45.9,27.4,45.9,29.4z M40.9,31.6
                                                                            c0,0.8,0.3,1.5,1.1,1.5c1.6,0,2-2.8,2-3.9c0-0.8-0.3-1.4-1.1-1.4C41.3,27.8,40.9,30.6,40.9,31.6z"/>
                                                                        <path d="M53.5,29.4c0,2.7-1.4,5.3-4.2,5.3c-1.9,0-2.9-1.3-2.9-3.1c0-2.4,1.3-5.4,4-5.4C52.6,26.2,53.5,27.4,53.5,29.4z M48.5,31.6
                                                                            c0,0.8,0.3,1.5,1.1,1.5c1.6,0,2-2.8,2-3.9c0-0.8-0.3-1.4-1.1-1.4C48.9,27.8,48.5,30.6,48.5,31.6z"/>
                                                                        <path d="M61.1,29.4c0,2.7-1.4,5.3-4.2,5.3c-1.9,0-2.9-1.3-2.9-3.1c0-2.4,1.3-5.4,4-5.4C60.2,26.2,61.1,27.4,61.1,29.4z M56,31.6
                                                                            c0,0.8,0.3,1.5,1.1,1.5c1.6,0,2-2.8,2-3.9c0-0.8-0.3-1.4-1.1-1.4C56.4,27.8,56,30.6,56,31.6z"/>
                                                                    </g>
                                                                </g>
                                                                </svg>
                                                            </span>
                                                            <input type="number" maxlength="4" v-model="cvc">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        -->
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="grid_col grid_col_phi_1_3">
                            <div class="grid_col_box checkout_cart">
                                <div class="cart_list cart_list_summary">
                                    <h3 class="text_m text_700">Carrito</h3>
                                    <div class="cart_list_item" v-for="(cart, index) in carrito" :key="index">
                                        <router-link :to="`/productos/${cart.producto.url}`" class="product_image" :style="{'background-image': `url('${cart.producto.img}')`}"></router-link>
                                        <div class="product_data">
                                            <div class="product_name">
                                                <router-link :to="`/productos/${cart.producto.url}`">{{cart.producto.name}}</router-link>
                                                <span class="delete_from_cart_btn" @click="delProduct(cart.producto.id, cart.producto.opcion)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"></path></svg>
                                                </span>
                                            </div>
                                            <p class="product_extra_info"
                                                v-for="(opcione, index) in cart.producto.opcion"
                                                :key="index"
                                            >{{cart.producto.options[index].find(el => el.id == opcione).text}}</p>
                                            <div class="product_price_details">
                                                <div class="product_quantity">
                                                    <span class="product_quantity_minus" @click="resCantidad(cart.producto.id)">-</span>
                                                    <span class="product_quantity_number">{{cart.cantidad}}</span>
                                                    <span class="product_quantity_plus" @click="sumCantidad(cart.producto.id)">+</span>
                                                </div>
                                                <div class="product_price">
                                                    {{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(cart.producto.price * cart.cantidad)}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="checkout_block">
                                    <div class="ammount_line">
                                        <span>Subtotal</span>
                                        <span>{{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(getSubTotalCarrito())}}</span>
                                    </div>
                                    <div class="ammount_line" v-if="this.hasEnvio()">
                                        <span>Gastos de envío</span>
                                        <span>{{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(envio ? paises.find(el => el.id == direccion.country.id)[envio == 1 ? 'ordinary' : (envio == 2 ? 'express' : 0)] : 0)}}</span>
                                    </div>
                                    <div class="ammount_line">
                                        <span>Total</span>
                                        <span>{{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(getTotalCarrito())}}</span>
                                    </div>
                                    <div class="input_block">
                                        <span class="checkout_line"><vity-check  v-model="terminos"/>Acepto los <router-link to="/legal">terminos y condiciones</router-link></span>
                                    </div>
                                    <div  class="btn med wide center grey mt20 mb10" @click="enviar">
                                        Realizar pedido
                                    </div>
                                    <div class="message ko" v-if="error"><h3 v-html="error"></h3></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import VityCheck from '@/components/VityCheck.vue'
import Direccion from '@/components/Direccion.vue'

export default {
    components:{
        VityCheck,
        Direccion
    },
    name: 'Checkout',
    data: function(){
        return {
            user: {
                addresses: []
            },
            // carrrito: [],
            envio: null,
            pago: null,
            direction: -1,
            direccion: {
                name: null,
                lastname: null,
                dni: null,
                phone: null,
                address: null,
                address2: null,
                cp: null,
                country: 0,
                province: 0,
                city: 0,
            },
            tarjeta: null,
            titular: null,
            mes: 0,
            year: 0,
            cvc: null,
            paises: [],
            error: null,
            terminos: false,
            years: [new Date().getFullYear(), new Date().getFullYear()+1, new Date().getFullYear()+2, new Date().getFullYear()+3, new Date().getFullYear()+4, new Date().getFullYear()+5, new Date().getFullYear()+6, new Date().getFullYear()+7, new Date().getFullYear()+8, new Date().getFullYear()+9, new Date().getFullYear()+10],
            months: ['01','02','03','04','05','06','07','08','09','10','11','12']
        }
    },
    methods: {
        delProduct: function(id, opcion){
            this.$store.commit('deleteProduct', id, opcion);
        },
        sumCantidad: function(id){
            this.$store.commit('addQuantity', id);
        },
        resCantidad: function(id){
            this.$store.commit('removeQuantity', id);
        },
        getSubTotalCarrito: function(){
            let precio = 0;
            this.carrito.forEach((x) => {
                precio += x.producto.price * x.cantidad;
            });
            return precio;
        },
        getTotalCarrito: function(){
            let precio = 0;
            this.carrito.forEach((x) => {
                precio += x.producto.price * x.cantidad;
            });
            if(this.direccion.country && this.envio != -1)
                precio += this.envio ? this.paises.find(el => el.id == this.direccion.country.id)[this.envio == 1 ? 'ordinary' : (this.envio == 2 ? 'express' : 0)] : 0;
            return precio;
        },
        get: function(address = -1){
            axios
                .get(process.env.VUE_APP_URL+`my_user?address=1`)
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.user = rs.data;
                        this.direction = address;
                    }
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        },
        getCountries: function(id){
            axios
                .get(process.env.VUE_APP_URL+'countries')
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.paises = rs.data;
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        },
        enviar: function(){
            this.error = '';
            if(!this.direccion.title){
                this.error += 'Falta el titulo<br>';
            }
            if(!this.direccion.name){
                this.error += 'Falta el nombre<br>';
            }
            if(!this.direccion.lastname){
                this.error += 'Falta el apellido<br>';
            }
            if(!this.direccion.phone){
                this.error += 'Falta el telefono<br>';
            }
            if(!this.direccion.address){
                this.error += 'Falta el nombre de dirección<br>';
            }
            if(!this.direccion.cp){
                this.error += 'Falta el Codigo Postal (CP)<br>';
            }
            if(!this.direccion.country){
                this.error += 'Falta el Pais<br>';
            }
            if(!this.direccion.province){
                this.error += 'Falta la provincia<br>';
            }
            if(!this.direccion.city){
                this.error += 'Falta la ciudad<br>';
            }

            if(this.needDni() && !this.direccion.dni){
                this.error += "Se necesita proporcionar el dni<br>";
            }

            if(!this.envio){
                this.error += 'Falta el tipo de envio<br>';
            }
            if(!this.pago){
                this.error += 'Falta el tipo de pago<br>';
            }

            if(!this.terminos){
                this.error += 'Falta aceptar los terminos y condiciones<br>';
            }

            if(this.error)
                return;

            this.error = null;
            axios
                .post(process.env.VUE_APP_URL+'purchase', {
                    address: JSON.stringify(this.direccion),
                    send: this.envio,
                    pay: this.pago,
                    productos: this.getProductsDataPurchase()
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        window.location = rs.data.url;
                    }
                    else{
                        if(rs.state == 'KO2'){
                            this.$store.commit('reloadCart');
                        }
                        this.error = rs.msg;
                    }
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        },
        getProductsDataPurchase: function(){
            const prods = [];
            for(let i = 0; i < this.$store.state.cart.length; i++){
                prods.push({"id": this.$store.state.cart[i].producto.id, "cantidad": this.$store.state.cart[i].cantidad});
            }
            return prods;
        },
        hasEnvio: function(){
            for(let i = 0; i < this.$store.state.cart.length; i++){
                if(this.$store.state.cart[i].producto.has_envio == 1){
                    return true;
                }
            }
            return false;
        },
        needDni: function(){
            for(let i = 0; i < this.$store.state.cart.length; i++){
                if(this.$store.state.cart[i].producto.need_dni == 1){
                    return true;
                }
            }
            return false;
        }
    },
    computed: {
        carrito() {
            return this.$store.getters.getCart;
        },
        countCart() {
            return this.$store.getters.getCart.length;
        }
    },
    created: function(){
        if(this.$route.query.errorpay){
            this.error = "Hubo un error en el proceso de pago";
        }
        if(this.countCart == 0){
            this.$router.push("/");
        }
        if(!this.hasEnvio()){
            this.envio = -1
        }
        if(!this.$store.state.user){
            window.oldUrl = '/checkout';
            this.$router.push('login');
        }
        else{
            this.get(this.$route.query.address);
            this.getCountries();
            if(this.$route.query.pay)
                this.pago = this.$route.query.pay;
            if(this.$route.query.send)
                this.envio = this.$route.query.send;
        }
    },
    watch:{
        direction: function(){
            if(this.direction == -1){
                this.direccion = {
                    name: null,
                    lastname: null,
                    dni: null,
                    phone: null,
                    address: null,
                    address2: null,
                    cp: null,
                    country: 0,
                    province: 0,
                    city: 0,
                };
            }
            else{
                this.direccion = this.user.addresses[this.direction];
            }
        },
        countCart: function(){
            if(this.countCart == 0){
                this.$router.push("/");
            }
        }
    }
}
</script>